import React from 'react';
import { useAuth } from 'providers/Auth';
import css from '../DashboardView/DashboardView.module.scss';
import { Panel } from 'components/Panel';
import { Container } from 'components/Container';
import { Hero } from 'components/Hero/Hero';
import HardHatPeople from 'assets/images/hard-hats-people.jpg';
import PeopleWalkingMedical from 'assets/images/people-walking-medical.jpg';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { TTableHeader } from 'components/Table';
import { Link } from 'components/Link';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { TableController } from 'components/Table/TableController';
import { UseQueryResult } from 'react-query';
import { Order, useGetOrderHistoryByCompanyId, useGetOrderQuotesByCompanyId } from 'api/order';
import { formatDate } from 'common/utils/formatDate';
import { formatAsUSD } from 'common/utils/formatAsUSD';
import { Split } from 'components/Split/Split';
import classes from 'classnames';
import { ProductMarketingIcon } from 'components/ProductMarketingIcon/ProductMarketingIcon';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';
import { CreateNewQuoteButton } from 'components/CreateNewQuoteButton';
import { TableActionList } from 'components/Table/TableActionList/TableActionList';
import { DuplicateOrderButton } from 'components/DuplicateOrderButton/DuplicateOrderButton';

export const DashboardView = () => {
	const tableBaseRoute = '/';
	const { isLoading: isAuthLoading, getCompanyId } = useAuth();

	const useQueryQuotes = (): UseQueryResult => {
		return useGetOrderQuotesByCompanyId(getCompanyId());
	};

	const dataSelectorQuotes = (data: Order[]) => {
		return sortArrayByProperty(data, 'updatedAt', 'desc').slice(0, 10);
	};

	const useQueryOrderHistory = (): UseQueryResult => {
		return useGetOrderHistoryByCompanyId(getCompanyId());
	};

	const dataSelectorOrderHistory = (data: Order[]) => {
		return sortArrayByProperty(data, 'submittedOn', 'desc').slice(0, 10);
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	const headersQuotes: TTableHeader[] = [
		{
			label: 'Name',
			property: 'name'
		},
		{
			label: 'Quote Id',
			property: 'poNumber'
		},
		{
			label: 'Date Created',
			property: 'createdAt',
			renderFunction: (data: Order) => (data.createdAt ? formatDate(data.createdAt, true, true) : '')
		},
		{
			// TODO add in the days valid to the bottom ? Justin ?
			label: 'Total',
			property: 'orderTotal',
			renderFunction: (data: Order) => (data.orderTotal ? formatAsUSD(data.orderTotal, true) : '')
		},
		{
			label: 'Status',
			property: 'status',
			className: css.smallColumn
		},
		{
			label: 'Action',
			property: 'action',
			className: css.smallColumn,
			renderFunction: (data: Order) => (
				<TableActionList
					tableActions={[
						<Link to={`/quotes/quote/${data.id}`}>Manage</Link>,
						<DuplicateOrderButton type={'link'} orderToDuplicate={data} />
					]}
				/>
			)
		}
	];

	const headersOrderHistory: TTableHeader[] = [
		{
			label: 'Order Name',
			property: 'name'
		},
		{
			label: 'PO Number',
			property: 'poNumber',
			className: css.smallColumn
		},
		{
			label: 'Date Last Updated',
			property: 'updatedAt',
			className: css.smallColumn,
			renderFunction: (data: Order) => (data.updatedAt ? formatDate(data.updatedAt, true, true) : '')
		},
		{
			label: 'Status',
			property: 'status',
			className: css.smallColumn
		},
		{
			label: 'Order Total',
			property: 'orderTotal',
			className: css.smallColumn,
			renderFunction: (data: Order) => (data.orderTotal ? formatAsUSD(data.orderTotal, true) : '')
		},
		{
			label: 'Action',
			property: 'action',
			className: css.smallColumn,
			renderFunction: (data: Order) => (
				<TableActionList
					tableActions={[
						<Link to={`/order-history/order/${data.id}`}>View</Link>,
						<DuplicateOrderButton type={'link'} orderToDuplicate={data} />
					]}
				/>
			)
		}
	];

	return (
		<PageFrame title={'My company overview'} HTMLTitle={'Dashboard'} isContentManagedTitle={true}>
			<Hero
				content={
					<>
						<h1>Get a quote today</h1>
						<p>Free shipping when you spend over $5,000 on any order.</p>
						<CreateNewQuoteButton type={'secondary'} />
					</>
				}
				image={HardHatPeople}
				imageAltText={'Get a quote today'}
			/>
			<Container>
				{/* Current Quotes */}
				<Section>
					<Panel
						title={'Current Quote(s)'}
						contentTopRight={
							<div>
								<div className={css.controls}>
									<Link to='/quotes'>View All</Link>
									<CreateNewQuoteButton type={'primary'} />
								</div>
							</div>
						}
					>
						<TableController
							headers={headersQuotes}
							baseRoute={tableBaseRoute}
							itemsPerPage={1000000}
							isUrlUpdated={false}
							useQuery={useQueryQuotes}
							dataSelector={dataSelectorQuotes}
							emptyMessage={'No items in list'}
							hasSearch={false}
						/>
					</Panel>
				</Section>
				{/* Recent Orders */}
				<Section>
					<Panel
						title={'Recent Orders'}
						contentTopRight={
							<div>
								<div className={css.controls}>
									<Link to='/order-history'>View All</Link>
								</div>
							</div>
						}
					>
						<TableController
							headers={headersOrderHistory}
							baseRoute={tableBaseRoute}
							itemsPerPage={1000000}
							isUrlUpdated={false}
							useQuery={useQueryOrderHistory}
							dataSelector={dataSelectorOrderHistory}
							emptyMessage={'No items in list'}
							hasSearch={false}
						/>
					</Panel>
				</Section>
			</Container>
			<Container>
				<Split
					type={'threeFourth_oneFourth'}
					contentLeft={
						<div>
							{
								<>
									<h2 className={classes(css.mediumText, css.ourProducts)}>Our Products</h2>
									<h3 className={classes('h1', css.largeBlueText)}>Interior Glass Systems</h3>
									<p className={'h6'}>
										dormakaba interior glass systems bring the benefits of glass to your projects.
									</p>
								</>
							}
						</div>
					}
					contentRight={
						<div className={classes(css.centerContainer, css.background)}>
							<Link to='/products/search/1' type={'primary'}>
								Search Products
							</Link>
						</div>
					}
					hasSplitMargin={false}
				/>
			</Container>
			<Section className={css.whiteBackground}>
				<Container>
					<ul className={css.iconContainer}>
						<li>
							<Link
								to={'/products/search/1?productCode=SLDG'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'ManualTopHungSlidingDoorSystem'} />
								<span>Manual Top-hung Sliding Door Systems</span>
							</Link>
						</li>
						<li>
							<Link
								to={'/products/search/1?productCode=UNPF'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'SwingDoorSystems'} />
								<span>Swing Door Systems</span>
							</Link>
						</li>
						<li>
							<Link
								to={'/products/search/1?productCode=MANT'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'SpecialtyWallSystems'} />
								<span>Specialty Wall Systems</span>
							</Link>
						</li>
						{/* <li>
							<Link
								to={'/products/search/1?productCode=CODEHERE'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'DemountablePartitionSystems'} />
								<span>Demountable Partition Systems</span>
							</Link>
						</li> */}
						{/* <li>
							<Link
								to={'/products/search/1?productCode=CODEHERE'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'PopularSystems'} />
								<span>Popular Systems</span>
							</Link>
						</li> */}
						<li>
							<Link
								to={'/products/search/1?productCode=HDAC'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'Headers'} />
								<span>Headers</span>
							</Link>
						</li>
						<li>
							<Link
								to={'/products/search/1?productCode=BTAC'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'ClosersAndPivots'} />
								<span>Closers &amp; Pivots</span>
							</Link>
						</li>
						<li>
							<Link
								to={'/products/search/1?productCode=DRS2'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'RailsAndChannels'} />
								<span>Rail &amp; Channel</span>
							</Link>
						</li>
						<li>
							<Link
								to={'/products/search/1?productCode=HDEU'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'PullsHandlesLocksets'} />
								<span>Pull, Handles, &amp; Locksets</span>
							</Link>
						</li>
						{/* <li>
							<Link
								to={'/products/search/1?productCode=EPFT'}
								classNames={{ buttonContent: css.iconLink }}
							>
								<ProductMarketingIcon type={'PatchFittingsHinges'} />
								<span>Patch Fittings &amp; Hinges</span>
							</Link>
						</li> */}
					</ul>
				</Container>
			</Section>
			<Hero
				content={
					<>
						<h4 className={'h6'}>Customer Service</h4>
						<h5 className={'h1'}>Need assistance?</h5>
						<p>Our Customer Support Team is here to help.</p>
						<Link type={'secondary'} to={'mailto:glasscustserv.us@dormakaba.com'} external={true}>
							{' '}
							Contact Us
						</Link>
					</>
				}
				image={PeopleWalkingMedical}
				imageAltText={'Get a quote today'}
			/>
		</PageFrame>
	);
};
