import React from 'react';
import css from './TableActionList.module.scss';
import { Button } from 'components/Button/Button';
import { Link } from 'components/Link/Link';

interface TableActionListProps {
	tableActions: (React.ReactElement<typeof Button> | React.ReactElement<typeof Link>)[];
}

export const TableActionList: React.FC<TableActionListProps> = ({ tableActions }) => {
	return (
		<div className={css.tableActionList}>
			{tableActions.map((tableAction, index) => {
				return (
					<React.Fragment key={index}>
						{index > 0 && <span className={css.tableActionPipe}>|</span>}
						{tableAction}
					</React.Fragment>
				);
			})}
		</div>
	);
};
