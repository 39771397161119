import React from 'react';
import { useAuth } from 'providers/Auth';
import css from './QuoteView.module.scss';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { Link } from 'components/Link';
import { UseQueryResult } from 'react-query';
import { Order, useGetOrderQuotesByCompanyId } from 'api/order';
import { TTableHeader } from 'components/Table/Table';
import { formatDate } from 'common/utils/formatDate';
import { Panel } from 'components/Panel/Panel';
import { TableController } from 'components/Table/TableController';
import { sortArrayByProperty } from 'common/utils/sortArrayByProperty';
import { CreateNewQuoteButton } from 'components/CreateNewQuoteButton';
import { formatOrderStatus } from 'common/utils/formatOrderStatus';
import { TableActionList } from 'components/Table/TableActionList/TableActionList';
import { DuplicateOrderButton } from 'components/DuplicateOrderButton';

export const QuoteView = () => {
	const { isLoading: isAuthLoading, getCompanyId } = useAuth();
	const tableBaseRoute = '/quotes/';

	const useQuery = (): UseQueryResult => {
		return useGetOrderQuotesByCompanyId(getCompanyId());
	};

	const headers: TTableHeader[] = [
		{
			label: 'Quote Name',
			property: 'name'
		},
		{
			label: 'Quote Id',
			property: 'orderConfirmationId',
			className: css.smallColumn
		},
		{
			label: 'Date Created',
			property: 'updatedAt',
			className: css.smallColumn,
			renderFunction: (data: Order) => (data.updatedAt ? formatDate(data.updatedAt, true, true) : '')
		},
		{
			label: 'Status',
			property: 'status',
			className: css.smallColumn,
			renderFunction: (data: Order) => formatOrderStatus(data.status)
		},
		{
			label: 'Action',
			property: 'action',
			className: css.smallColumn,
			renderFunction: (data: Order) => (
				<TableActionList
					tableActions={[
						<Link to={`/quotes/quote/${data.id}`}>Manage</Link>,
						<DuplicateOrderButton type={'link'} orderToDuplicate={data} />
					]}
				/>
			)
		}
	];

	const dataSelector = (data: Order[]) => {
		return sortArrayByProperty(data, 'updatedAt', 'desc');
	};

	if (isAuthLoading) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<PageFrame
			title={'Current Quote(s)'}
			ContentTopRight={() => <CreateNewQuoteButton type={'primary'} />}
			description={
				<>
					<span className={css.boldText}>Please note pricing on quotes is only valid for 30 days. </span>
					<span className={css.italicText}>
						If more than 30 days have passed your estimated total could change.
					</span>
				</>
			}
		>
			<Container>
				<Section>
					<Panel>
						<TableController
							headers={headers}
							baseRoute={tableBaseRoute}
							itemsPerPage={10}
							useQuery={useQuery}
							isUrlUpdated={true}
							hasPagination={true}
							emptyMessage={'No quotes found!'}
							dataSelector={dataSelector}
							totalCountSelector={(data: any) => data?.length}
							hasSearch={false}
							tableHandlesPaginatingData={true}
						/>
					</Panel>
				</Section>
			</Container>
		</PageFrame>
	);
};
