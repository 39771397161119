import React, { PropsWithChildren, useMemo } from 'react';
import css from './DuplicateOrderButton.module.scss';
import { useModal } from 'providers/Modal/ModalProvider';
import { Input } from 'components/Form/Input';
import { Order, useDuplicateOrder } from 'api/order';
import { Button } from 'components/Button';
import { useNavigate } from 'react-router';
import { TButtonTypes } from 'common/types/TButtonTypes';

type DuplicateOrderButtonProps = {
	type: TButtonTypes;
	orderToDuplicate: Order;
};

export const DuplicateOrderButton: React.FC<PropsWithChildren<DuplicateOrderButtonProps>> = props => {
	const { type, orderToDuplicate, children } = props;
	const navigate = useNavigate();

	const initialCreateNewQuoteValues = useMemo(() => {
		return {
			newQuoteName: `Copy of ${orderToDuplicate.name}`
		};
	}, [orderToDuplicate]);

	const { createModal, removeModal, setModalLoading, setIsModalError } =
		useModal<typeof initialCreateNewQuoteValues>();

	const { mutateAsync: duplicateOrder } = useDuplicateOrder();

	const handleDuplicateQuoteModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		createModal({
			sourceEvent: event,
			heading: 'Duplicate Quote',
			Content: props => {
				const { formik } = props;
				return (
					<>
						<p className={css.mt0}>
							<strong className={css.strong}>
								Are you sure you want to duplicate {orderToDuplicate.name}?
							</strong>{' '}
							<br />
							If so please rename the quote and Please note pricing on quotes is only valid for 30 days
							and your estimated total could change.
						</p>
						<form onSubmit={formik.handleSubmit}>
							<Input
								name={'newQuoteName'}
								label={'New Quote Name'}
								placeholder={'Enter quote name'}
								formikProps={formik}
							/>
						</form>
						<p>
							<strong>Please note pricing on quotes is only valid for 30 days.</strong> If more than 30
							days have passed your estimated total could change.
						</p>
					</>
				);
			},
			secondaryButtonLabel: 'Cancel',
			primaryButtonLabel: 'Duplicate',
			primaryButtonAction: async formikSubmit => {
				formikSubmit();
			},
			formikConfig: {
				initialValues: initialCreateNewQuoteValues,
				enableReinitialize: true,
				onSubmit: async formValues => {
					setIsModalError(false);
					setModalLoading(true);
					try {
						const response = await duplicateOrder({
							order: orderToDuplicate,
							newName: formValues.newQuoteName
						});
						removeModal();
						navigate(`/quotes/quote/${response.id}`);
					} catch {
						setIsModalError(true);
					}
					setModalLoading(false);
				}
			}
		});
	};

	return (
		<Button type={type} onClick={event => handleDuplicateQuoteModal(event)}>
			{children ? children : 'Duplicate'}
		</Button>
	);
};
