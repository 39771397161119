import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'providers/Auth';
import css from './CustomerQuoteDetailView.module.scss';
import classes from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'components/Container';
import { Spinner } from 'components/Spinner';
import { LoadingDelay } from 'components/LoadingDelay';
import { PageFrame } from 'components/PageFrame';
import { Link } from 'components/Link';
import { OrderType, useGetOrderById } from 'api/order';
import { QuoteDetailLineItems } from '../common/QuoteDetailLineItems/QuoteDetailLineItems';
import { QuoteDetailMessages } from '../common/QuoteDetailMessages/QuoteDetailMessages';
import { QuoteDetailEditForm } from '../common/QuoteDetailEditForm/QuoteDetailEditForm';
import { QuoteDetails } from '../common/QuoteDetails/QuoteDetails';
import { QuoteAdditionalDetails } from '../common/QuoteAdditionalDetails/QuoteAdditionalDetails';
import { QuoteDetailSidebarStatusAndPrice } from '../common/QuoteDetailSidebarStatusAndPrice/QuoteDetailSidebarStatusAndPrice';
import { Button } from 'components/Button';
import { Panel } from 'components/Panel';
import { ErrorView } from 'views/views';
import { Toggle } from 'components/Toggle/Toggle';
import { DropdownList } from 'components/DropdownList/DropdownList';
import { TDropdownItem } from 'common/types/TDropdownItem';
import { useModal } from 'providers/Modal/ModalProvider';
import { useSingleQuote } from 'providers/SingleQuote/SingleQuoteProvider';
import { CreateOpeningSavedLocationState } from 'components/CreateOpening/common/types';
import { QuoteUploads } from '../common/QuoteUploads/QuoteUploads';
import { Alert } from 'components/Alert/Alert';
import { DuplicateOrderButton } from 'components/DuplicateOrderButton/DuplicateOrderButton';

export interface CustomerQuoteDetailViewProps {
	showFooter?: boolean;
	mode?: OrderType;
}

export const CustomerQuoteDetailView: React.FC<CustomerQuoteDetailViewProps> = props => {
	const { showFooter = true, mode = 'stock-cpq' } = props;
	const { isLoading: isAuthLoading, getCompanyId } = useAuth();
	const {
		isQuoteDataValid,
		singleQuoteData,
		setSingleQuoteData,
		showSaveMessage,
		setIsQuoteReadyToSubmit
	} = useSingleQuote();
	const [showDiscount, setShowDiscount] = useState(true);
	const [quoteSaver, setQuoteSaver] = useState<() => Promise<void> | null>();
	const navigate = useNavigate();
	const { createModal, removeModal } = useModal();
	const { orderId } = useParams<{ orderId: string }>();
	const skipSubmitWarning = localStorage.getItem('skipSubmitWarning'); // should equal string 'skip'

	const {
		data: getOrderData,
		isLoading: isGetOrderLoading,
		error: getOrderError
	} = useGetOrderById(orderId);

	// Set the context with the updated order data.
	useEffect(() => {
		if (!isGetOrderLoading) {
			setSingleQuoteData(getOrderData);
		}
	}, [isGetOrderLoading, setSingleQuoteData, getOrderData]);

	const handleSaveOrder = async (event: any) => {
		await quoteSaver();
	};

	const handleSubmitOrder = useCallback(
		async (event: any) => {
			if (skipSubmitWarning === 'skip') {
				if (isQuoteDataValid) {
					setIsQuoteReadyToSubmit(true);
					await quoteSaver();
				} else {
					setIsQuoteReadyToSubmit(false);
					await quoteSaver();
				}
			} else {
				createModal({
					sourceEvent: event,
					heading: 'Are you ready to submit your quote?',
					Content: () => {
						return (
							<>
								<p>
									Once submitting your quote you will not be able to change any details or line items. If
									any updates need to be made then use the messages below on your order.{' '}
								</p>
								<Button
									type={'link'}
									onClick={() => {
										localStorage.setItem('skipSubmitWarning', 'skip');
									}}
								>
									Don't show me this again.
								</Button>
							</>
						);
					},
					primaryButtonLabel: 'Confirm submit',
					primaryButtonAction: async () => {
						if (isQuoteDataValid) {
							setIsQuoteReadyToSubmit(true);
							await quoteSaver();
						} else {
							setIsQuoteReadyToSubmit(false);
							await quoteSaver();
						}
						removeModal();
					}
				});
			}
		},
		[quoteSaver, createModal, isQuoteDataValid, removeModal, setIsQuoteReadyToSubmit, skipSubmitWarning]
	);

	// Print Options
	const printDropdownItems: TDropdownItem[] = [
		{
			title: 'Show Discount',
			action: () => {
				setShowDiscount(true);
				window.print();
			}
		},
		{
			title: 'No Discount',
			action: () => {
				setShowDiscount(false);
				setTimeout(() => {
					window.print();
				}, 100);
			}
		}
	];

	// Loading & Errors
	if (getOrderError) {
		return <ErrorView isInAppFrame={true} />;
	}
	if (isAuthLoading || isGetOrderLoading || !singleQuoteData) {
		return (
			<LoadingDelay>
				<Spinner type='darker' size='large' isCenteredHorizontally isCenteredVertically></Spinner>
			</LoadingDelay>
		);
	}

	return (
		<>
			<PageFrame
				title={singleQuoteData?.name ?? 'New Order'}
				isContentManagedTitle={true}
				showFooter={showFooter}
			>
				<Container className={classes(css.appframe, { [css.width100]: mode === 'createopening' })}>
					{/* Left side content */}
					<div className={classes(css.main)}>
						{/* Top Title & Pricing toggle */}
						<div className={classes(css.flexContainer, css.justifySpaceBetween)}>
							{/* Top title and back button */}
							<div>
								{/* Order Name */}
								<h1 className={classes('h3', css.orderTitle)}>{singleQuoteData?.name ?? 'New Order'}</h1>
								{/* Back to all Quotes */}
							</div>
							{/* View pricing toggle */}
							<div className={classes(css.flexContainer, css.hideFromPrint)}>
								<p className={css.viewPricingLabel}>{'VIEW PRICING: '} </p>{' '}
								<Toggle
									checked={showDiscount}
									label={''}
									onChange={event => {
										setShowDiscount(event.currentTarget.checked);
									}}
								/>
							</div>
						</div>

						{mode === 'stock-cpq' && (
							<Button
								type={'link'}
								className={css.hideFromPrint}
								iconLeft={{
									type: 'CaretLeft',
									color: 'blue'
								}}
								onClick={() => {
									navigate(-1);
								}}
							>
								{singleQuoteData?.status === 'OrderPlaced' ? 'Back to all orders' : 'Back to all quotes'}
							</Button>
						)}

						{/* Disclaimers */}
						<div className={css.orderDisclaimer}>
							<span className={classes(css.boldText, css.italicText)}>
								Please note pricing on quotes is only valid for 30 days.{' '}
							</span>
							<span className={css.italicText}>
								If more than 30 days have passed your estimated total could change.{' '}
							</span>

							<span className={classes(css.boldText, css.italicText)}>
								Got a question or need help?{' '}
							</span>
							<span className={css.italicText}>
								Leave us a message below and we'll get back to you or{' '}
								<Link
									to={'mailto:glasscustserv.us@dormakaba.com'}
									className={css.inlineLink}
									classNames={{ buttonContent: css.inlineLink }}
									external={true}
								>
									{' '}
									contact us.
								</Link>
							</span>
						</div>

						{/* Formik form for the order OR Sections of labels */}
						{showSaveMessage && showSaveMessage === 'success' && (
							<Alert type={'success-light'}>Your quote was saved successfully.</Alert>
						)}
						{singleQuoteData && singleQuoteData.status && singleQuoteData.status === 'NotSubmitted' ? (
							<QuoteDetailEditForm setFormSubmitter={setQuoteSaver} companyId={getCompanyId()} />
						) : (
							<QuoteDetails orderData={singleQuoteData} />
						)}

						<QuoteDetailLineItems
							orderId={orderId}
							orderData={singleQuoteData}
							showDiscount={showDiscount}
							canEditLineItems={
								singleQuoteData && singleQuoteData.status && singleQuoteData.status === 'NotSubmitted'
							}
						/>

						{/* Additional Details */}
						{singleQuoteData?.orderType === 'createopening' && singleQuoteData?.openingSaveStateObj && (
							<QuoteAdditionalDetails orderData={singleQuoteData} />
						)}

						{/* If order type is create an opening or has uploads */}
						{singleQuoteData &&
							singleQuoteData.orderUploads &&
							singleQuoteData.orderUploads.length > 0 && <QuoteUploads orderData={singleQuoteData} />}

						{/* Messages */}
						<QuoteDetailMessages orderId={orderId} orderData={singleQuoteData} />
					</div>

					{/* right side content */}
					<div className={classes(css.rightSidePanel)}>
						{/* Print Page Button  */}
						<div className={classes(css.flexContainer, css.hideFromPrint)}>
							<DuplicateOrderButton type={'link'} orderToDuplicate={singleQuoteData}>
								Duplicate Quote
							</DuplicateOrderButton>
							<DropdownList
								className={css.marginLeftAuto}
								iconRight={{
									type: 'CaretDown',
									color: 'white'
								}}
								size='small'
								items={printDropdownItems}
							>
								Print Quote
							</DropdownList>
						</div>

						<QuoteDetailSidebarStatusAndPrice orderData={singleQuoteData} showDiscount={showDiscount} />

						{singleQuoteData?.status === 'NotSubmitted' && (
							<Panel className={css.orderControl}>
								{singleQuoteData?.orderType === 'createopening' && mode === 'stock-cpq' ? (
									<Button
										type='primary'
										className={classes(css.submit, css.hideFromPrint)}
										onClick={event => {
											const createOpeningState: CreateOpeningSavedLocationState = JSON.parse(
												singleQuoteData?.openingSaveStateObj
											);
											navigate(`/createopening/${singleQuoteData.id}`, {
												state: {
													...createOpeningState
												}
											});
										}}
									>
										Continue to Order
									</Button>
								) : (
									<Button
										type='primary'
										className={classes(css.submit, css.hideFromPrint)}
										onClick={event => {
											event.persist();
											handleSubmitOrder(event);
										}}
									>
										Submit
									</Button>
								)}
								<Button
									type={'link'}
									className={css.saveOpening}
									onClick={async event => {
										handleSaveOrder(event);
									}}
								>
									Save for later
								</Button>
							</Panel>
						)}
					</div>
				</Container>
			</PageFrame>
		</>
	);
};
